export const LOCALES =
  process.env.NODE_ENV !== 'production'
    ? {
        HI: 'Hillcrest',
        NE: 'North Edsa',
        SF: 'San Fernando',
        LP: 'Las Piñas',
        MA: 'Malabon',
        PG: 'Pasig',
      }
    : {
        HI: 'Hillcrest',
        PA: 'Panorama',
        NE: 'North Edsa',
        SF: 'San Fernando',
        LP: 'Las Piñas',
        MA: 'Malabon',
        PG: 'Pasig',
      };

// Quick hack for now
export const LOCALES_WITH_AREA_ID =
  process.env.NODE_ENV !== 'production'
    ? {
        HI1: 'Hillcrest',
        NE1: 'North Edsa',
        SF1: 'San Fernando',
        LP1: 'Las Piñas',
        MA1: 'Malabon',
        PG1: 'Pasig',
      }
    : {
        HI1: 'Hillcrest',
        NE1: 'North Edsa',
        SF1: 'San Fernando',
        LP1: 'Las Piñas',
        MA1: 'Malabon',
        PA1: 'Escarpment',
        PA5: 'Suites',
        PA6: 'Canley',
        PG1: 'Pasig',
      };

export const LOCALES_BY_AREA = {
  Caloocan: ['NE1'],
  'Las Piñas': ['LP1'],
  Malabon: ['MA1'],
  Pampanga: ['SF1'],
  Pasig: ['HI1', 'PA1', 'PA5', 'PA6'],
};

export const PANORAMA_AREAS = [
  { name: 'Escarpment - Panorama', id: 1 },
  { name: 'Suites - Panorama', id: 5 },
  { name: 'Canley - Panorama', id: 6 },
];

export const ROOM_TYPE_COLORS = {
  standard: 'bg-black text-white',
  deluxe: 'bg-teal text-white',
  'super deluxe': 'bg-midnight text-white',
  'mini suite': 'bg-lollipop text-white',
  suite: 'bg-burgundy text-white',
  'thematic suite': 'bg-gold text-white',
};

export const ROOM_TYPES = {
  standard: ['standard'],
  deluxe: ['deluxe', 'super deluxe'],
  suite: ['suite', 'mini suite'],
  thematic: ['thematic suite'],
};

// static on frontend, this is basically the allowed rates
// [room_rate id]: Name
export const RATES = {
  5: 'Half Day (12 hrs)',
  6: 'Whole Day (24 hrs)',
};

// export const PARTY_RATES = {
//   32: 'Party 10 pax (5hrs)',
//   29: 'Party 15 pax (5hrs)',
// };

export const PARTY_RATES =
  process.env.NODE_ENV !== 'production'
    ? {
        32: '5 hrs',
        29: '12 hrs',
      }
    : {
        250: '5 hrs',
        251: '12 hrs',
      };

export const SUPER_THEMATIC_PARTY_RATES_CONVERSION =
  process.env.NODE_ENV !== 'production'
    ? {
        32: 32,
        29: 29,
      }
    : {
        250: 252,
        251: 253,
      };

// ! hardcoded values; hack for `fetchOtherDetails`
// ! easiest way atm to deal with the ids
// export const PARTY_RATE_IDS_CONVERSION = {
export const PARTY_RATE_IDS_CONVERSION =
  process.env.NODE_ENV !== 'production'
    ? {
        hi: { 32: 32, 29: 29 },
        ne: { 32: 32, 29: 29 },
        ba: { 32: 32, 29: 29 },
        ma: { 32: 32, 29: 29 },
        lp: { 32: 32, 29: 29 },
        sf: { 32: 32, 29: 29 },
      }
    : {
        hi: { 250: 847, 251: 848, 252: 849, 253: 850 },
        pa: { 250: 861, 251: 862, 252: 863, 253: 864 },
        ne: { 250: 250, 251: 251, 252: 252, 253: 253 },
        ba: { 250: 250, 251: 251, 252: 252, 253: 253 },
        ma: { 250: 250, 251: 251, 252: 252, 253: 253 },
        lp: { 250: 842, 251: 843, 252: 844, 253: 845 },
        sf: { 250: 870, 251: 871, 252: 872, 253: 873 },
      };

// ! hardcoded values; hack for `fetchOtherDetails`
// ! easiest way atm to deal with the ids
export const SUPER_THEMATIC_ROOMS = {
  pa: [132, 133, 134, 135, 136, 108, 109, 131],
};

// this can be static as long as the amenities don't change
export const AMENITIES = [
  {
    img: '/img/icons/car-garage.svg',
    category: 'garage',
    id: 'garage',
    name: 'Garage',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/smarttv.svg',
    category: 'TV',
    id: 1,
    name: 'LED TV',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/smarttv.svg',
    category: 'TV',
    id: 4,
    name: 'Smart TV',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/karaoke.svg',
    category: 'Videoke',
    id: 3,
    name: 'Videoke',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/couple.svg',
    category: 'Tantra',
    id: 5,
    name: 'Tantra',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/ref.svg',
    category: 'Mini Ref',
    id: 6,
    name: 'Mini Ref',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/jacuzzi.svg',
    category: 'Jacuzzi',
    id: 291,
    name: 'Jacuzzi',
  },
  {
    img: 'https://motel.victoriacourt.com/amenities_svg/bathtub.svg',
    category: 'Bath Tub',
    id: 292,
    name: 'Bath Tub',
  },
];

export const FUCENT_API_ENDPOINT = {
  gcashpay: 'Cashier.Payment.JsapiPay',
  paymaya: 'Cashier.Payment.JsapiPay',
  grabpay: 'Cashier.Payment.JsapiPay',
  weixin: 'Cashier.Payment.ScanPay',
  alipay: 'Cashier.Payment.AliOnlinePay',
  // alipayaps: 'Cashier.Payment.JsapiPay',
  // unionpay: 'Cashier.Payment.ScanPay',
};

export const FUCENT_KEYS = {
  hi: {
    apiKey: process.env.FUCENT_API_KEY_HI,
    signKey: process.env.FUCENT_SIGN_KEY_HI,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_HI,
  },
  pa: {
    apiKey: process.env.FUCENT_API_KEY_PA,
    signKey: process.env.FUCENT_SIGN_KEY_PA,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_PA,
  },
  ne: {
    apiKey: process.env.FUCENT_API_KEY_NE,
    signKey: process.env.FUCENT_SIGN_KEY_NE,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_NE,
  },
  ba: {
    apiKey: process.env.FUCENT_API_KEY_BA,
    signKey: process.env.FUCENT_SIGN_KEY_BA,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_BA,
  },
  ma: {
    apiKey: process.env.FUCENT_API_KEY_MA,
    signKey: process.env.FUCENT_SIGN_KEY_MA,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_MA,
  },
  lp: {
    apiKey: process.env.FUCENT_API_KEY_LP,
    signKey: process.env.FUCENT_SIGN_KEY_LP,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_LP,
  },
  sf: {
    apiKey: process.env.FUCENT_API_KEY_SF,
    signKey: process.env.FUCENT_SIGN_KEY_SF,
    notifyKey: process.env.FUCENT_NOTIFY_KEY_SF,
  },
};

export const STL_STATUSES = [
  5, 37, 38, 14, 35, 24, 49, 50, 51, 52, 60, 61, 62, 67, 22, 15, 70, 69, 68,
];
